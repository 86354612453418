@import 'colors';

html,
body,
div#root {
    background: #fff;
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono';
}

.ant-layout {
    height: 100vh;
}
.ant-layout, .ant-layout-header {
    background: #fff;
}

.topnav .ant-menu-horizontal {
    border-bottom: none;
}
.topnav h2 {
    color: $color-yellow;
}
.topnav .user {
    float: right;
    display: flex;
    margin-top: 15px;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
}
.topnav .user .name {
    font-size: 12px;
    color: $color-yellow;
    line-height: 20px;
}
.topnav .user .email {
    font-size: 10px;
    color: $color-text;
    line-height: 20px;
}
.topnav .user li.signout {
    color: $color-red;
}

.ant-layout-footer {
    background: #fff;
    text-align: center;
}
.ant-layout-footer .poweredby {
    font-size: 10px;
    color: $color-green;
}

#signin {
    text-align: center;
}
#signin .login-button {
    margin: 60px auto;
}

#dashboard {
    margin-top: 100px;
	text-align: center;
}

#registrations {
    margin-top: 100px;
}

#users {
    margin-top: 100px;
}
@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('fonts/RobotoMono-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}
